// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Sticky footer styles */
.sticky-footer {
    background-color: #22526c;
    color: white;
    position: fixed;
    bottom: 0;
    left: 0; /* Ensure it starts from the left side */
    right: 0; /* Ensure it stretches to the right side */
    padding: 20px;
    padding-bottom: 40px;
    text-align: right;
    height: 32px;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.5s ease-in-out; /* Smooth fade effect */
    z-index: 1000; /* Keep it above other elements */
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    gap: 6px;
}

.sticky-footer.show {
    opacity: 0.95; /* Show when U-value is available */
}

/* Text alignment */
.sticky-footer div {
    text-align: right;
    height: fit-content;
}
`, "",{"version":3,"sources":["webpack://./src/ProductDisplay.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,SAAS;IACT,OAAO,EAAE,wCAAwC;IACjD,QAAQ,EAAE,0CAA0C;IACpD,aAAa;IACb,oBAAoB;IACpB,iBAAiB;IACjB,YAAY;IACZ,UAAU,EAAE,qBAAqB;IACjC,oCAAoC,EAAE,uBAAuB;IAC7D,aAAa,EAAE,iCAAiC;IAChD,aAAa;IACb,yBAAyB;IACzB,qBAAqB;IACrB,QAAQ;AACZ;;AAEA;IACI,aAAa,EAAE,mCAAmC;AACtD;;AAEA,mBAAmB;AACnB;IACI,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":["/* Sticky footer styles */\n.sticky-footer {\n    background-color: #22526c;\n    color: white;\n    position: fixed;\n    bottom: 0;\n    left: 0; /* Ensure it starts from the left side */\n    right: 0; /* Ensure it stretches to the right side */\n    padding: 20px;\n    padding-bottom: 40px;\n    text-align: right;\n    height: 32px;\n    opacity: 0; /* Initially hidden */\n    transition: opacity 0.5s ease-in-out; /* Smooth fade effect */\n    z-index: 1000; /* Keep it above other elements */\n    display: flex;\n    justify-content: flex-end;\n    align-items: baseline;\n    gap: 6px;\n}\n\n.sticky-footer.show {\n    opacity: 0.95; /* Show when U-value is available */\n}\n\n/* Text alignment */\n.sticky-footer div {\n    text-align: right;\n    height: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
