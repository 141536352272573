import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './MobileProductDisplay.css';
import EmailQuote from './EmailQuote';

const MobileProductDisplay = ({ product, result, formData, productUrl, newQuote }) => {
    const [imageUrl, setImageUrl] = useState(null);
    
    // URL of the logo image
    const logoUrl = "https://uvalue-calculator.nimbus.sugarprojects.com/wp-content/uploads/2024/09/Mannok_Logo.png";

    useEffect(() => {
        if (product && product.featured_media) {
            // Fetch the featured media (image)
            axios
                .get(`https://uvalue-calculator.nimbus.sugarprojects.com/wp-json/wp/v2/media/${product.featured_media}`)
                .then(response => {
                    setImageUrl(response.data.source_url); // Set the image URL
                })
                .catch(err => {
                    console.error('Error fetching the featured image:', err);
                });
        } else {
            setImageUrl(null); // Set the image URL
        }
    }, [product]);

    const displayUValue = () => {
        if (result && result.uvalue !== null) {
            if (typeof result.uvalue === 'number') {
                return result.uvalue.toFixed(2); // Format to 2 decimal places
            } else {
                return result.uvalue; // Return as is if it's not a number
            }
        }
        return "0.00"; // Default display if uvalue is null
    };

    const uValue = displayUValue();

    return (
        <div>
            {uValue !== '0.00' ? (
            <div className='footer-container'>
                <div className="product-info-container">
                        <div className="info-wrapper">
                            {/* Conditionally render product image */}
                            {product && imageUrl ? (
                                <div className="image-wrapper">
                                    <img
                                        src={imageUrl}
                                        alt={product.title.rendered}
                                        className="product-image"
                                    />
                                </div>
                            ) : (
                                <p className="no-image">No image available for this product</p>
                            )}
                            <div className="u-value-section">
                                <div className="u-value-label">U-Value</div>
                                <div className='u-value-container'>
                                    <div id="UValueDisplay" className="u-value-display">{uValue}</div>
                                    {/* Conditionally render the unit based on uValue */}
                                    {uValue !== "Calculating..." && (
                                        <div className="u-value-unit">W/m²K</div>
                                    )}
                                </div>
                            </div>
                        </div>
                </div>
                {result && result.uvalue !== '0.00' && (
                    <EmailQuote formData={formData} productUrl={productUrl} newQuote={newQuote}/>
                )}
            </div>
            ) : null}
        </div>
    );    
};    

export default MobileProductDisplay;