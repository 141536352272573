import React, { useEffect, useState } from 'react';
import './MenuModal.css';

const MenuModal = ({ isOpen, onClose, quoteHistory }) => {
    const [isVisible, setIsVisible] = useState(false);  // Controls rendering
    const [isFadingIn, setIsFadingIn] = useState(false);  // Controls fade-in animation
    const [isQuoteHistoryVisible, setIsQuoteHistoryVisible] = useState(false);  // Controls visibility of quote history

    useEffect(() => {
        if (isOpen) {
            setIsVisible(true);  // Show the modal immediately

            // Disable background scrolling on both <html> and <body>
            document.documentElement.style.overflow = 'hidden';  // <html>
            document.body.style.overflow = 'hidden';  // <body>

            // Delay applying the 'fade-in' class to ensure transition works
            const timeout = setTimeout(() => setIsFadingIn(true), 10);
            return () => clearTimeout(timeout);
        } else {
            setIsFadingIn(false);  // Trigger fade-out

            // Re-enable background scroll after fade-out completes
            const timeout = setTimeout(() => {
                setIsVisible(false);
                // Restore normal scrolling
                document.documentElement.style.overflow = '';  // <html>
                document.body.style.overflow = '';  // <body>
            }, 300);
            return () => clearTimeout(timeout);
        }
    }, [isOpen]);

    const handleQuoteHistoryClick = () => {
        // Toggle visibility of the quote history list
        setIsQuoteHistoryVisible((prev) => !prev);
    };

    const sendTestNotification = () => {
        if ('Notification' in window) {
            // Request Notification Permission
            if (Notification.permission === 'granted') {
                showNotification();
            } else if (Notification.permission !== 'denied') {
                Notification.requestPermission().then(permission => {
                    if (permission === 'granted') {
                        showNotification();
                    }
                });
            }
        }
    };

    const showNotification = () => {
        // Check if the service worker is available and registered
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then(registration => {
                registration.showNotification('Test Notification', {
                    body: 'This is a test notification triggered by a button click!',
                    icon: '/M.png' // Optional: add your app icon path
                });
            });
        }
    };

    if (!isVisible) return null;  // Prevent rendering when not visible

    return (
        <div className={`menu-modal-overlay ${isFadingIn ? 'fade-in' : 'fade-out'}`}>
            <div className="menu-modal">
                <button className="close-button" onClick={onClose}>X</button>
                <ul className="menu-links">
                    <li className='page-link'><a href="https://mannokbuild.com/insulation-boards/floor-insulation/">Floor Insulation</a></li>
                    <li className='page-link'><a href="https://mannokbuild.com/insulation-boards/wall-insulation/">Wall Insulation</a></li>
                    <li className='page-link'><a href="https://mannokbuild.com/insulation-boards/pitched-roof/">Pitched Roof Insulation</a></li>
                    <li className='page-link'><a href="https://mannokbuild.com/insulation-boards/flat-roof/">Flat Roof Insulation</a></li>
                    <li className='page-link'>
                        <a href="#" onClick={handleQuoteHistoryClick}>Quote History</a>
                        {isQuoteHistoryVisible && (
                            <ul className="quote-history-list">
                                {quoteHistory.toReversed().map((quote) => (
                                    <li key={quote.id}>
                                        <a href={quote.url}>{quote.timestamp}</a>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                    <li><button className='uk-button uk-button-muted' onClick={sendTestNotification}>Send Test Notification</button></li>
                </ul>
            </div>
        </div>
    );
};

export default MenuModal;


