import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './Modal.css'; // Add custom styling for modal

const LocationOccupationModal = ({ show, onClose }) => {
    const [location, setLocation] = useState('');
    const [occupation, setOccupation] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);  // Track terms acceptance
    const [error, setError] = useState(null);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const userInfo = Cookies.get('userInfo');
        if (userInfo) {
            onClose(); // Close the modal if info is already saved
        }
    }, [onClose]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!location || !occupation) {
            setError('Both fields are required');
            return;
        }

        try {
            const response = await axios.post(
                'https://uvalue-calculator.nimbus.sugarprojects.com/wp-json/wp/v2/location-occupation',
                { location, occupation }
            );

            if (response.status === 200) {
                Cookies.set('userInfo', { location, occupation }, { expires: 30 });
                setMessage('Information submitted successfully!');
                onClose(); // Close modal after successful submission
            } else {
                setError('Failed to submit information.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setError('An error occurred. Please try again later.');
        }
    };

    if (!show) {
        return null; // Don't render the modal if it's not needed
    }

    // Check if the button should be disabled (if location, occupation, and terms are not valid)
    const isSubmitDisabled = !location || !occupation || !termsAccepted;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Please select your location and occupation</h2>
                {error && <p className="error-message">{error}</p>}
                {message && <p className="success-message">{message}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Location</label>
                        <div className="radio-group">
                            <label>
                                <input
                                    type="radio"
                                    name="location"
                                    value="GB/NI"
                                    className="uk-radio"
                                    checked={location === 'GB/NI'}
                                    onChange={(e) => setLocation(e.target.value)}
                                />
                                GB/NI
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="location"
                                    value="Ireland"
                                    className="uk-radio"
                                    checked={location === 'Ireland'}
                                    onChange={(e) => setLocation(e.target.value)}
                                />
                                Ireland
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="location"
                                    value="Other"
                                    className="uk-radio"
                                    checked={location === 'Other'}
                                    onChange={(e) => setLocation(e.target.value)}
                                />
                                Other
                            </label>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Occupation</label>
                        <div className="radio-group">
                            <label>
                                <input
                                    type="radio"
                                    name="occupation"
                                    value="Architect"
                                    className="uk-radio"
                                    checked={occupation === 'Architect'}
                                    onChange={(e) => setOccupation(e.target.value)}
                                />
                                Architect
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="occupation"
                                    value="Surveyor"
                                    className="uk-radio"
                                    checked={occupation === 'Surveyor'}
                                    onChange={(e) => setOccupation(e.target.value)}
                                />
                                Surveyor
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="occupation"
                                    value="Engineer"
                                    className="uk-radio"
                                    checked={occupation === 'Engineer'}
                                    onChange={(e) => setOccupation(e.target.value)}
                                />
                                Engineer
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="occupation"
                                    value="Builders Merchant"
                                    className="uk-radio"
                                    checked={occupation === 'Builders Merchant'}
                                    onChange={(e) => setOccupation(e.target.value)}
                                />
                                Builders Merchant
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="occupation"
                                    value="Contractor"
                                    className="uk-radio"
                                    checked={occupation === 'Contractor'}
                                    onChange={(e) => setOccupation(e.target.value)}
                                />
                                Contractor
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="occupation"
                                    value="Self Build"
                                    className="uk-radio"
                                    checked={occupation === 'Self Build'}
                                    onChange={(e) => setOccupation(e.target.value)}
                                />
                                Self Build
                            </label>
                        </div>
                    </div>

                    <div className="terms-conditions">
                        <label>
                            <input
                                type="checkbox"
                                id="form-email-terms"
                                className="uk-checkbox uk-margin-small-right"
                                checked={termsAccepted}
                                onChange={(e) => setTermsAccepted(e.target.checked)}
                            />
                            I accept the{' '}
                            <a target="_blank" href="https://www.mannokbuild.com/disclaimer/">
                                Terms and Conditions
                            </a>{' '}
                            and{' '}
                            <a target="_blank" href="https://www.mannokbuild.com/privacy-policy/">
                                Privacy Policy
                            </a>
                        </label>
                    </div>

                    <button
                        type="submit"
                        className="submit-button uk-button uk-button-secondary uk-width-1-1"
                        disabled={isSubmitDisabled}  // Disable button if inputs are invalid
                    >
                        Continue
                    </button>
                </form>
            </div>
        </div>
    );
};

export default LocationOccupationModal;