// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dimension-input-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.input-container{
    width: 33%;
}

@media (max-width: 500px) {
    .dimension-input-container{
        flex-direction: column;
        width: 100%;
        gap: 5px;
    }

    .input-container{
        width: 100%;
    }

    .input-container input{
        margin-bottom: 0px!important;
    }
}`, "",{"version":3,"sources":["webpack://./src/dimensionsInput.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,UAAU;AACd;;AAEA;IACI;QACI,sBAAsB;QACtB,WAAW;QACX,QAAQ;IACZ;;IAEA;QACI,WAAW;IACf;;IAEA;QACI,4BAA4B;IAChC;AACJ","sourcesContent":[".dimension-input-container{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 10px;\n}\n\n.input-container{\n    width: 33%;\n}\n\n@media (max-width: 500px) {\n    .dimension-input-container{\n        flex-direction: column;\n        width: 100%;\n        gap: 5px;\n    }\n\n    .input-container{\n        width: 100%;\n    }\n\n    .input-container input{\n        margin-bottom: 0px!important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
